<template>
  <div id="management">
    <div class="content">
      <div class="container">
        <p class="tab-setting" v-if="updateClinic == true">แก้ไขคลินิก</p>
        <p class="tab-setting" v-else>เพิ่มคลินิก</p>
        <div class="box-manage-admin">
          <el-row :gutter="30">
            <el-col :span="24" class="set-box name-list">
              <p>ข้อมูลคลินิก</p>
            </el-col>
            <el-form
              ref="form"
              :label-position="'top'"
              :model="form"
              :rules="rules"
            >
              <el-col :span="24" class="set-box">
                <el-row :gutter="15">
                  <el-col :span="24">
                    <el-upload
                      action="#"
                      class="box-image-upload box-profile text-center"
                      ref="upload"
                      list-type="picture-card"
                      :auto-upload="false"
                      :on-change="uploadImage"
                      :show-file-list="false"
                    >
                      <img
                        v-if="showDefault == false ||  form.illustration == ''"
                        src="../assets/image/user.png"
                        class="showavatar 1"
                      />
                      <img
                        v-else-if="typeof image.raw == 'undefined'"
                        :src="
                          callImage +
                          'service/' +
                          form.serviceId +
                          '/' +
                          form.illustration
                        "
                        class="showavatar 1"
                      />
                      <img
                        v-else
                        :src="getUrlImage(image)"
                        class="showavatar"
                      />
                      <div slot="default">
                        <p class="selectImg">เลือกรูปภาพประกอบ</p>
                      </div>
                    </el-upload>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item
                      label="ชื่อศูนย์บริการทางการแพทย์"
                      prop="serviceId"
                    >
                      <el-select
                        v-model="form.serviceId"
                        placeholder="กรุณาเลือกศูนย์บริการ"
                        @change="getDepartmentById"
                      >
                        <el-option
                          v-for="item in dataService"
                          :key="item._id"
                          :label="item.nameTH"
                          :value="item._id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="ชื่อคลินิก" prop="name">
                      <el-input v-model="form.name"> </el-input> </el-form-item
                  ></el-col>

                  <el-col :span="24" :sm="12" :md="12" >
                    <el-form-item label="หมายเลขโทรศัพท์" >
                      <el-input
                        v-model="form.phone[0]"
                        maxlength="9"
                        @keypress.native="isNumber($event)"
                      ></el-input> </el-form-item
                  ></el-col>

                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="Website">
                      <el-input
                        v-model="form.website"
                      ></el-input> </el-form-item
                  ></el-col>

                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="Email">
                      <el-input v-model="form.email"></el-input> </el-form-item
                  ></el-col>

                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="ที่อยู่" prop="address">
                      <el-input v-model="form.address">
                      </el-input> </el-form-item
                  ></el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="รหัสไปรษณีย์" prop="postcode">
                      <ThailandAutoComplete
                        v-model="form.postcode"
                        type="zipcode"
                        @select="selectAddress"
                        @input="checkValid('postcode')"
                        color="#409EFF"
                        class="form-input"
                        @keypress.native="isNumber($event)"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="ตำบล/แขวง" prop="subDistrict">
                      <ThailandAutoComplete
                        v-model="form.subDistrict"
                        type="district"
                        @select="selectAddress"
                        @input="checkValid('subDistrict')"
                        color="#409EFF"
                        class="form-input"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="อำเภอ/เขต" prop="district">
                      <ThailandAutoComplete
                        v-model="form.district"
                        type="amphoe"
                        @select="selectAddress"
                        @input="checkValid('district')"
                        color="#409EFF"
                        class="form-input"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="จังหวัด" prop="province">
                      <ThailandAutoComplete
                        v-model="form.province"
                        type="province"
                        @select="selectAddress"
                        @input="checkValid('province')"
                        color="#409EFF"
                        class="form-input"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-row :gutter="15">
                      <el-col :span="12"
                        ><el-form-item label="ละติจูด"
                          ><el-input v-model="form.lat"></el-input>
                        </el-form-item> </el-col
                      ><el-col :span="12"
                        ><el-form-item label="ลองติจูด"
                          ><el-input v-model="form.lng"></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="24" :md="12" class="openHoliday">
                    <el-form-item
                      label="บริการในวันหยุดนักขัตฤกษ์"
                      prop="closedOnPublicHoliday"
                    >
                      <el-row :gutter="15">
                        <el-col :span="12">
                          <el-radio-group v-model="form.closedOnPublicHoliday">
                            <el-radio :label="true">เปิด</el-radio>
                          </el-radio-group>
                        </el-col>
                        <el-col :span="12">
                          <el-radio-group v-model="form.closedOnPublicHoliday">
                            <el-radio :label="false">ปิด</el-radio>
                          </el-radio-group>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </el-col>
                  <el-col
                    :span="24"
                    v-for="(roundOpenDay, index) in form.openDay"
                    :key="index"
                  >
                    <el-row :gutter="15">
                      <el-col :span="24" :md="6">
                        <el-form-item
                          label="วันที่เปิด"
                          :prop="'openDay.' + index + '.day'"
                          :rules="{
                            required: true,
                            message: 'กรุณาเลือกวันที่เปิด',
                            trigger: ['blur', 'change'],
                          }"
                        >
                          <el-select
                            v-model="roundOpenDay.day"
                            placeholder="กรุณาเลือกวันที่เปิด"
                          >
                            <el-option
                              v-for="item in dataDay"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24" :md="6">
                        <el-form-item
                          label="เวลาเปิด"
                          :prop="'openDay.' + index + '.open'"
                          :rules="{
                            required: true,
                            message: 'กรุณาเลือกเวลาเปิด',
                            trigger: ['blur', 'change'],
                          }"
                        >
                          <el-time-select
                            placeholder="เวลาเปิด"
                            v-model="roundOpenDay.open"
                            :picker-options="{
                              start: '06:00',
                              step: '00:30',
                              end: '19:30',
                            }"
                          >
                          </el-time-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24" :md="6">
                        <el-form-item
                          label="เวลาปิด"
                          :prop="'openDay.' + index + '.close'"
                          :rules="{
                            required: true,
                            message: 'กรุณาเลือกเวลาปิด',
                            trigger: ['blur', 'change'],
                          }"
                        >
                          <el-time-select
                            placeholder="เวลาเปิด"
                            v-model="roundOpenDay.close"
                            :picker-options="{
                              start: '06:00',
                              step: '00:30',
                              end: '19:30',
                            }"
                          >
                          </el-time-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24" :md="6" class="box-openday">
                        <el-row :gutter="15">
                          <el-col :span="12" :sm="12" :md="12">
                            <el-form-item label="เปิดปิด">
                              <el-button
                                type="primary"
                                class="add-openday"
                                @click="addRowDay()"
                                >เพิ่มวันที่เปิด</el-button
                              >
                            </el-form-item></el-col
                          >
                          <el-col :span="12" :sm="12" :md="12">
                            <el-form-item label="เปิดปิด">
                              <el-button
                                type="primary"
                                class="del-openday"
                                @click="delRowDay(index)"
                                >ลบ</el-button
                              >
                            </el-form-item></el-col
                          >
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="รายละเอียด" prop="detail">
                      <el-input
                        type="textarea"
                        :rows="2"
                        v-model="form.detail"
                        class="setborder"
                      >
                      </el-input> </el-form-item
                  ></el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="คีย์เวิร์ด" prop="keyword">
                      <el-input
                        type="textarea"
                        :rows="2"
                        v-model="form.keyword"
                        class="setborder"
                      >
                      </el-input> </el-form-item
                  ></el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="ชื่อสาขาวิชา">
                      <el-select
                        v-model="form.department"
                        placeholder="กรุณาเลือกศูนย์บริการ"
                      >
                        <el-option
                          v-for="item in loopDepartment"
                          :key="item._id"
                          :label="item.name"
                          :value="item._id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :sm="12" :md="12">
                    <el-form-item label="แท็ก">
                      <el-select
                        v-model="form.tags"
                        multiple
                        placeholder="Select"
                      >
                        <el-option
                          v-for="item in symptomList"
                          :key="item.id"
                          :label="item.value"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
            </el-form>
          </el-row>
        </div>
        <div class="setbtn">
          <router-link to="/manageclinic">
            <button class="btn btn-color-dark btn-cancle">
              <span>ยกเลิก</span>
            </button>
          </router-link>
          <button
            class="btn btn-color-dark btn-save"
            @click="updateFormClinic()"
            v-if="updateClinic == true"
          >
            <span>บันทึก</span>
          </button>
          <button
            class="btn btn-color-dark btn-save"
            @click="createClinic()"
            v-else
          >
            <span>บันทึก</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/service/axios.js";
import ThailandAutoComplete from "vue-thailand-address-autocomplete";
export default {
  computed: {
    token() {
      return this.$store.state.token;
    },
    symptomList() {
      return this.$store.state.symptomList;
    },
  },
  mounted() {
    this.getClinicById();
    this.getDataService();
  },
  components: {
    ThailandAutoComplete,
  },
  props: {
    routeParams: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      updateClinic: false,
      callImage: process.env.VUE_APP_BASE_URL_GET,
      showDefault: false,
      showProfile: true,
      image: "",
      dataService: [],
      fileList: [],
      dataDay: [
        {
          value: "จันทร์",
          label: "วันจันทร์",
        },
        {
          value: "อังคาร",
          label: "วันอังคาร",
        },
        {
          value: "พุธ",
          label: "วันพุธ",
        },
        {
          value: "พฤหัสบดี",
          label: "วันพฤหัสบดี",
        },
        {
          value: "ศุกร์",
          label: "วันศุกร์",
        },
        {
          value: "เสาร์",
          label: "วันเสาร์",
        },
        {
          value: "อาทิตย์",
          label: "วันอาทิตย์",
        },
      ],
      loopDepartment: [],
      form: {
        serviceId: "",
        name: "",
        illustration: "",
        address: "",
        subDistrict: "",
        district: "",
        province: "",
        postcode: "",
        lat: 0,
        lng: 0,
        phone: [],
        email: "",
        website: "",
        closedOnPublicHoliday: "",
        openDay: [
          {
            day: "",
            open: "",
            close: "",
          },
        ],
        department: "",
        detail: "",
        keyword: "",
        tags: [],
      },
      rules: {
        detail: [
          {
            required: true,
            message: "กรุณากรอกรายละเอียด",
            trigger: "blur",
          },
        ],
        keyword: [
          {
            required: true,
            message: "กรุณากรอกคีย์เวิร์ด",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "กรุณากรอกชื่อคลินิก",
            trigger: "blur",
          },
        ],
        serviceId: [
          {
            required: true,
            message: "กรุณาเลือกศูนย์บริการ",
            trigger: ["blur", "change"],
          },
        ],
        closedOnPublicHoliday: [
          {
            required: true,
            message: "กรุณาเลือกการให้บริการในวันหยุดนักขัตฤกษ์",
            trigger: "change",
          },
        ],
        address: [
          {
            required: true,
            message: "กรุณากรอกที่อยู่",
            trigger: "blur",
          },
        ],
        subDistrict: [
          {
            required: true,
            message: "กรุณากรอกตำบล",
            trigger: ["blur", "change"],
          },
        ],
        district: [
          {
            required: true,
            message: "กรุณากรอกอำเภอ",
            trigger: ["blur", "change"],
          },
        ],
        province: [
          {
            required: true,
            message: "กรุณากรอกจังหวัด",
            trigger: ["blur", "change"],
          },
        ],
        postcode: [
          {
            required: true,
            numberValid: true,
            min: 5,
            max: 5,
            message: "กรุณากรอกรหัสไปรษณีย์ให้ถูกต้อง",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    getClinicById() {
      if (typeof this.routeParams.serviceId != "undefined") {
        HTTP.get(`clinic/${this.routeParams.serviceId}`)
          .then((res) => {
            if (res.data.success) {
              this.form = res.data.obj;
              this.updateClinic = true;
              this.showDefault = true;
            } else {
              this.alertFailError();
            }
          })
          .catch((e) => {
            this.alertCatchError(e);
            console.log("getClinicById error", e);
          })
          .finally(() => {
            setTimeout(() => {
              this.loading = false;
            }, 500);
          });
      }
    },
    getDepartmentById() {
      console.log("asdasdadadadadads");
      if (this.form.serviceId != "") {
        let dataFilter = this.dataService.filter(
          (filter) => filter._id == this.form.serviceId
        );
        if (typeof dataFilter[0].department != "undefined") {
          this.loopDepartment = dataFilter[0].department;
        }
      }
    },
    getDataService() {
      HTTP.get(`services/${0}/${0}/${"all"}/0`)
        .then((res) => {
          if (res.data.success) {
            this.dataService = res.data.obj;
            this.getDepartmentById();
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("getDataService error", e);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    delRowDay(index) {
      if (this.form.openDay.length > 1) {
        this.form.openDay.splice(index, 1);
      }
    },
    addRowDay() {
      this.form.openDay.push({
        day: "",
        open: "",
        close: "",
      });
    },
    checkValid(field) {
      this.$refs["form"].validateField(field);
    },
    selectAddress(address) {
      this.form.postcode = address.zipcode;
      this.form.subDistrict = address.district;
      this.form.district = address.amphoe;
      this.form.province = address.province;
    },
    updateFormClinic() {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      var formData = new FormData();
      formData.append("serviceId", this.form.serviceId);
      formData.append("name", this.form.name);
      formData.append("address", this.form.address);
      formData.append("district", this.form.district);
      formData.append("subDistrict", this.form.subDistrict);
      formData.append("province", this.form.province);
      formData.append("postcode", this.form.postcode);
      formData.append("illustration", this.form.illustration);
      formData.append("lat", this.form.lat);
      formData.append("lng", this.form.lng);
      formData.append("phone", this.form.phone);
      formData.append("email", this.form.email);
      formData.append("website", this.form.website);
      formData.append("detail", this.form.detail);
      formData.append("keyword", this.form.keyword);
      formData.append("closedOnPublicHoliday", this.form.closedOnPublicHoliday);

      formData.append("department", this.form.department);
      this.form.openDay.map((data) => {
        formData.append("openDay", JSON.stringify(data));
      });
       this.form.tags.map((data) => {
        formData.append("tags", JSON.stringify(data));
      });
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.put(`clinic/${this.form._id}`, formData, config)
        .then((res) => {
          if (res.data.success) {
            this.$message({
              message: "แก้ไขข้อมูลสำเร็จ",
              type: "success",
              duration: 4000,
            });
            this.$router.push(`/manageclinic`);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("update clinic error", e);
        });
    },

    createClinic() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          var formData = new FormData();
          formData.append("serviceId", this.form.serviceId);
          formData.append("name", this.form.name);
          formData.append("address", this.form.address);
          formData.append("district", this.form.district);
          formData.append("subDistrict", this.form.subDistrict);
          formData.append("province", this.form.province);
          formData.append("postcode", this.form.postcode);
          formData.append("illustration", this.form.illustration);
          formData.append("lat", this.form.lat);
          formData.append("lng", this.form.lng);
          formData.append("phone", this.form.phone);
          formData.append("email", this.form.email);
          formData.append("website", this.form.website);
          formData.append("detail", this.form.detail);
          formData.append("keyword", this.form.keyword);
            this.form.tags.map((data) => {
        formData.append("tags", JSON.stringify(data));
      });
          formData.append(
            "closedOnPublicHoliday",
            this.form.closedOnPublicHoliday
          );

          formData.append("department", this.form.department);
          this.form.openDay.map((data) => {
            formData.append("openDay", JSON.stringify(data));
          });
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          HTTP.post(`cilnic`, formData, config)
            .then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "บันทึกข้อมูลสำเร็จ",
                  type: "success",
                  duration: 4000,
                });
                this.$router.push(`/manageclinic`);
              } else {
                this.alertFailError();
              }
            })
            .catch((e) => {
              this.alertCatchError(e);
              console.log("create service error", e);
            });
        }
      });
    },
    getUrlImage(image) {
      if (image != null) {
        return URL.createObjectURL(image.raw);
      } else {
        return "";
      }
    },
    uploadImage(file) {
      this.showProfile = false;
      this.image = file;
      this.showDefault = true;
      setTimeout(() => {
        this.showProfile = true;
      }, 100);
      this.form.illustration = this.image.raw;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
  },
};
</script><style scoped>
.btn-save {
  margin-top: 20px;
  width: 154px;
  font-size: 16px;
  margin-left: 15px;
}
.btn-cancle {
  margin-top: 20px;
  width: 154px;
  font-size: 16px;
  background: transparent;
  color: #7e828f;
  border: 1px solid #7e828f;
}
.btn-upload {
  width: 100%;
  display: block;
  padding: 11px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  color: #ffffff;
  font-family: "Kanit", sans-serif;
  background: #9e76b4;
  cursor: pointer;
}
.setborder {
  border: 1px solid #9e76b4 !important;
  border-radius: 4px;
}
</style>